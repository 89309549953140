$white: #ffffff;
$gray-0: #d0d0d0;
$gray-1: #333333;
$gray-2: #4f4f4f;
$gray-3: #828282;
$gray-5: #e0e0e0;
$gray-6: #f2f2f2;
$gray-7: #f7f7f7;
$gray-10: #f5f6f8;
$gray-11: #d1d1d1;
$gray-12: #b2b2b2;
$blue-2: #2d9cdb;
$blue-3: #97b0bd;
$blue-4: #f4f9fc;
$blue-5: #bdccd4;
$blue-6: #dbe5eb;
$blue-6-05: #dbe5eb81;
$blue-7: #2193b0;
$blue-7-1: #2193b020;
$estp: #3f628e;
$gray_blue: #a0a4b5;
$gray_blue-0-2: rgba(160, 164, 181, 0.2);
$light_gray: #d7d7d7;
$green-1: #27ae60;
$green-2: #6fcf97;
$orange-1: #f2994a;
$alert-orange-color: #fcf4db;
$red: #eb5757;

$gradient-0: linear-gradient(
    161.78deg,
    #0890bb 19.05%,
    rgba(8, 144, 187, 0.5) 39.83%,
    rgba(0, 194, 255, 0) 59.76%
);
$gradient-0-1: linear-gradient(
    113.96deg,
    #0080a8 0%,
    rgba(0, 194, 255, 0.1) 100%
);
$gradient-org: linear-gradient(180deg, #f3d072 0%, #f79a83 98.75%);
$gradient-org-270: linear-gradient(270deg, #f3d072 0%, #f79a83 98.75%);
$gradient-org-90: linear-gradient(90deg, #f3d072 0%, #f79a83 98.75%);
$gradient-info: linear-gradient(179.76deg, #ffffff 0.2%, #879fc9 99.8%);
$gradient-info-005: linear-gradient(
    179.76deg,
    rgba(255, 255, 255, 0.05) 0.2%,
    rgba(135, 159, 201, 0.05) 99.8%
);
$gradient-1: linear-gradient(90deg, #9687c0 0%, #ecbad6 100%);
$gradient-3: linear-gradient(
    90deg,
    #f5989e 0.19%,
    #fad0c4 99.37%,
    #fad0c4 100.37%
);
$gradient-6: linear-gradient(90deg, #aad485 0%, #aad485 2%, #6cbe45 100%);
$gradient-6-01: linear-gradient(
    90deg,
    rgba(170, 212, 133, 0.01) 0%,
    rgba(170, 212, 133, 0.01) 2%,
    rgba(108, 190, 69, 0.01) 100%
);
$gradient-7: linear-gradient(
    90deg,
    #d8c3df 0.04%,
    #95c2e8 99.01%,
    #95c2e8 100%
);
$gradient-7-01: linear-gradient(
    90deg,
    rgba(216, 195, 223, 0.01) 0.04%,
    rgba(149, 194, 232, 0.01) 99.01%,
    rgba(149, 194, 232, 0.01) 100%
);
$gradient-8: linear-gradient(90deg, #c1c160 0%, #d4d4b1 97.81%, #d4d4b1 99.81%);
$gradient-10: linear-gradient(90deg, #23b5df 0.01%, #8ecfae 100%);
$gradient-11: linear-gradient(87.4deg, #879fc9 2.17%, #b9d9d9 97.89%);
$gradient-blue-custom: linear-gradient(90deg, #6dd5ed 0%, #2193b0 100%);
$gradient-ellipse-purple: linear-gradient(
    41.27deg,
    #d8c3df 14.6%,
    rgba(149, 194, 232, 0) 84.96%
);
$gradient-ellipse-green: linear-gradient(
    90deg,
    #aad485 0%,
    rgba(108, 190, 69, 0) 100%
);
$gradient-ellipse-red: linear-gradient(
    90deg,
    rgba(243, 208, 114, 0) 0%,
    #f79a83 100%
);
$gradient-ellipse-blue: linear-gradient(
    113.96deg,
    #0081a881 0%,
    rgba(0, 194, 255, 0.1) 100%
);
$gradient-ellipse-orange: linear-gradient(
    90deg,
    rgba(243, 208, 114, 0) 0%,
    #f79a837e 100%
);
$gradient-ellipse-violet: linear-gradient(
    41.27deg,
    #d8c3df 14.6%,
    rgba(149, 194, 232, 0) 84.96%
);
$gradient-green-90: linear-gradient(
    180deg,
    #aad485 0%,
    #aad485 2%,
    #6cbe45 100%
);
$gradient-violet-90: linear-gradient(0deg, #9687c0 0%, #ecbad6 100%);
$gradient-blue-90: linear-gradient(360deg, #2193b0 4.08%, #6dd5ed 100%);
$gradient-blue-2-0: linear-gradient(360deg, #24abcd 4.08%, #6dd5ed 100%);
$gradient-blue-3-0: linear-gradient(180deg, #00d0f7 0%, #007eee 100%);
$gradient-orange-180: linear-gradient(180deg, #f3d072 0%, #ff985e 98.75%);
$gradient-orange-2-0: linear-gradient(180deg, #ff9944 0%, #fc6076 100%);
$gradient-gray: linear-gradient(360deg, #2c3e50 0%, #828282 100%);
$grad-ellipse-lightBlue: linear-gradient(
    113.96deg,
    #0080a8 0%,
    rgba(0, 194, 255, 0.1) 100%
);

$gray-blue-1: rgba(143, 210, 243, 0.2);
$gray-border: #d7d7d7;
$content-padding: 160px;

$font-primary: Gilroy, Roboto, sans-serif;
$font-secondary: Roboto, sans-serif;
