@import '../../../styles/variables';

.container {
    width: 100%;
}

.title,
.link {
    &:hover {
        > span {
            color: $estp;
            color: var(--estp);
        }
        > svg {
            > path {
                stroke: $estp;
                stroke: var(--estp);
            }
        }
    }
    > span {
        font-family: $font-primary;
        font-family: var(--font-primary);
        font-style: normal;
        letter-spacing: 0;
    }
}

.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: $gray-1;
    color: var(--gray-1);
    margin-bottom: 65px;
}

.list {
    display: flex;
    width: 100%;
    margin: 0;
}

.item {
    width: 208px;
    height: 260px;

    margin-right: 20px;

    &:last-of-type {
        margin-right: 0;
    }
}

.pagination {
    margin-top: 2.25rem;
    display: flex;
    justify-content: center;
}

.right {
    margin-left: 12px;
}

.left {
    margin-left: calc(50% - 17px - 6px);
}

.btn {
    cursor: pointer;
    width: 16px;
    height: 10px;
}

.disabled {
    opacity: 0.5;
}

.link {
    margin-left: auto;
    margin-right: 50px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.link > span {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: $blue-2;
    color: var(--blue-2);
    margin-right: 12px;
}
