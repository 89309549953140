@import '../../../styles/variables';

.container {
    width: 100%;
    height: 100%;
    background: radial-gradient(
        100% 250.69% at 0% 0%,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.1) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    backdrop-filter: blur(12px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    color: $gray-1;
    color: var(--gray-1);
    border-radius: 16px;
}

.left,
.right {
    text-align: center;
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
    letter-spacing: 0;
    font-weight: 700;
}

.left {
    width: 6rem;
    font-size: 52px;
    line-height: 63px;
}

.right {
    text-align: start;
    width: 144px;
    font-size: 16px;
    line-height: 19px;
}

.delimiter {
    width: 1px;
    height: 100%;
    opacity: 0.5;
    background-color: $gray_blue;
    background-color: var(--gray_blue);
    margin: auto 1.5rem;
}
