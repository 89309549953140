@import '../../../styles/variables';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.item {
    width: 21rem;
    height: 8.25rem;
    margin: 0.5rem 4rem 0.5rem auto;
}

.description {
    width: 100%;
    padding: 25px;
    text-align: right;
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    color: $gray-2;
    color: var(--gray-2);
    position: absolute;
    bottom: -3.6rem;
}
