@import '../../../styles/variables';

.container {
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
}

.item {
    width: auto;
}

.delimiter {
    height: 63px;
    width: 1px;
    background-color: $gray_blue;
    background-color: var(--gray_blue);
    margin: auto 40px;
    opacity: 0.4;

    @media (max-width: 767px) {
        display: none;
    }
}
