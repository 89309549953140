@import '../../../styles/variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 767px) {
        margin-bottom: 1rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.count,
.text {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
    letter-spacing: 0;
}

.count {
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    color: $gray-1;
    color: var(--gray-1);
    white-space: nowrap;
}

.text {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: $gray-blue;
    color: var(--gray_blue);
}
