@import '../../../styles/variables';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.price,
.company {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: 700;
}

.price {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 8px;
    color: $gray-1;
    color: var(--gray-1);

    @media (max-width: 484px) {
        font-size: 24px;
        line-height: 29px;
    }
}

.company {
    font-size: 14px;
    line-height: 17px;
    color: $gray_blue;
    color: var(--gray_blue);

    @media (max-width: 484px) {
        font-size: 13px;
        line-height: 16px;
    }
}
