@import '../../../styles/variables';

.container {
    width: 100%;
    padding-left: 6rem;

    @media (max-width: 640px) {
        padding-left: 1.5rem;
    }
}

.title {
    font-family: $font-primary;
    font-family: var(--font-primary);
    margin-top: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0;
    color: var(--gray-1);
    width: 100%;

    @media (max-width: 899px) {
        font-size: 40px;
        line-height: 48px;
    }
}

.list {
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.item {
    width: 220px;
    margin: 15px 15px 15px 0;

    @media (max-width: 484px) {
        width: 100%;
        margin: 8px 0;
    }
}

.pagination {
    margin-top: 60px;
    display: flex;
}

.right {
    margin-left: 12px;
}

.btn {
    cursor: pointer;
    width: 16px;
    height: 10px;
}

.disabled {
    opacity: 0.5;
}
