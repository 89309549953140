@import '../../../styles/variables';

.container,
.search {
    width: 100%;
    display: flex;
    position: relative;
}

.container {
    flex-direction: column;
}

.input,
.placeholder,
.extended {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
}

.input {
    border-radius: 12px 0 0 12px;
    box-sizing: border-box;
    height: 48px;
    padding: 16px;
    width: calc(100% - 70px);
    background-color: $gray-10;
    background-color: var(--gray-10);
    color: $gray-1;
    color: var(--gray-1);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.input {
    outline: none;
    border: none;
}

.input::placeholder,
.input:-ms-input-placeholder,
.input::-ms-input-placeholder {
    color: $gray_blue;
    color: var(--gray_blue);
    opacity: 1;

    @media (max-width: 767px) {
        min-width: 13.5rem;
        max-width: 90%;
        font-size: 12px;
        line-height: 15px;
    }
}

.button {
    outline: none;
    border-radius: 0 12px 12px 0;
    border: none;
    height: 48px;
    width: 70px;
    background: $gradient-org;
    background: var(--gradient-org);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.extended {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $blue-2;
    color: var(--blue-2);
    margin-top: 16px;
    margin-left: 16px;
    text-decoration: none;
}
