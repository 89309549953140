@import '../../styles/variables';

.container {
    width: 100%;
    background: var(--white) url(/assets/main/bg.png);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.bg {
    width: 1120px;
    height: 48rem;
    position: relative;

    @media (max-width: 1119px) {
        width: 100%;
    }

    @media (max-width: 767px) {
        height: 100%;
    }
}

.bg > svg {
    top: 156px;
    right: -20rem;
    position: absolute;
    z-index: 1;

    @media (max-width: 1119px) {
        top: -120px;
    }

    @media (max-width: 767px) {
        top: -270px;
        right: -350px;
    }
}

.content {
    position: relative;
    z-index: 2;
    width: calc(100% - 480px);
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1119px) {
        width: 36rem;
        margin-left: 3rem;
    }

    @media (max-width: 767px) {
        min-width: 19.5rem;
        max-width: 90%;
        margin-left: 1.5rem;
    }
}

.title {
    display: flex;
    flex-direction: column;
    margin-top: 130px;

    @media (max-width: 767px) {
        margin-top: 25rem;
    }
}

.title > h1,
.about > h2,
.link > span,
.about > p {
    font-family: $font-primary;
    font-family: var(--font-primary);
    font-style: normal;
    letter-spacing: 0;
}

.title > h1 {
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
    color: $gray-1;
    color: var(--gray-1);

    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
    }
}

.search {
    width: 100%;
}

.counter {
    width: 100%;
    margin-top: 104px;

    @media (max-width: 767px) {
        margin-top: 4.9rem;
    }
}

.info {
    width: 1120px;
    padding: 0 0 80px;
    position: relative;

    @media (max-width: 1119px) {
        width: 100%;
    }
}

.news {
    margin-top: 114px;
    width: 100%;
    position: relative;
    z-index: 4;

    @media (max-width: 767px) {
        margin-top: 2.5rem;
    }
}

.link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: fit-content;

    &:hover {
        > span {
            color: $estp;
            color: var(--estp);
        }
        > svg > path {
            stroke: $estp;
            stroke: var(--estp);
        }
    }

    > span {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: $blue-2;
        color: var(--blue-2);
        margin-right: 12px;
    }
}

.about {
    margin-top: 8rem;
    padding: 0 4rem;
    width: 100%;

    > h2 {
        color: $gray-1;
        color: var(--gray-1);
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
        max-width: 670px;
        position: relative;
        z-index: 4;
        margin-bottom: 40px;

        @media (max-width: 640px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 1.5rem;
        }
    }

    > p {
        color: $gray-1;
        color: var(--gray-1);
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        margin-top: 20px;
        max-width: 530px;
        position: relative;
        z-index: 4;
    }

    @media (max-width: 640px) {
        margin: 7rem 1.5rem 0 1.5rem;
        padding: 0;
        width: 92%;
    }
}

.aboutFooter {
    margin-top: 48px;
    position: relative;
    z-index: 4;

    @media (max-width: 640px) {
        margin-top: 1.5rem;
    }
}

.centerBlock {
    position: relative;
    margin-top: 120px;
    display: flex;
    align-items: flex-end;

    @media (min-width: 900px) and (max-width: 1119px) {
        width: 100%;
    }
}

.centerBlockItem {
    width: 50%;
    height: 100%;
    position: relative;
    z-index: 4;

    @media (max-width: 899px) {
        width: 100%;

        &:last-of-type {
            display: none;
        }
    }
}

.companies {
    margin-top: 10rem;
    width: 100%;
}

// static spheres for mobile devices
@mixin sphere {
    position: absolute;
    border: 1px solid transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blueSphere {
    @include sphere;

    right: -200px;
    bottom: -85px;
    width: 311px;
    height: 311px;
    background: $gradient-ellipse-blue;
    background: var(--gradient-ellipse-blue);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
    transform: rotate(105deg);
}
.greenSphere {
    @include sphere;

    bottom: -200px;
    right: -190px;
    width: 324px;
    height: 324px;
    background: $gradient-ellipse-green;
    background: var(--gradient-ellipse-green);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}
.redSphere {
    @include sphere;

    right: 295px;
    top: -250px;
    width: 324px;
    height: 324px;
    background: $gradient-ellipse-red;
    background: var(--gradient-ellipse-red);
    filter: drop-shadow(10px 10px 50px rgba(143, 210, 243, 0.1));
}
