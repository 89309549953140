@import '../../../styles/variables';

.container {
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.header > h2,
.link {
    &:hover {
        > span {
            color: $estp;
            color: var(--estp);
        }

        > svg > path {
            stroke: $estp;
            stroke: var(--estp);
        }
    }
    > span {
        font-family: $font-primary;
        font-family: var(--font-primary);
        font-style: normal;
        letter-spacing: 0;
    }
}

.header > h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: $gray-1;
    color: var(--gray-1);

    @media (max-width: 1119px) {
        margin-left: 1.5rem;
    }
}

.link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 58px;

    @media (max-width: 1119px) {
        margin-right: 1.5rem;
    }
}

.link > span {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: $blue-2;
    color: var(--blue-2);
    margin-right: 12px;
}

.cards {
    width: 100%;
    // display: flex;
    // justify-content: flex-start;

    > div {
        display: flex;
        justify-content: flex-start;
    }

    @media (max-width: 1119px) {
        margin-left: 1.5rem;
        width: 1120px;
    }

    > div {
        margin-right: 1.5rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
